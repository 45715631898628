import { EventManager } from '@lindar-joy/goosicorn-event-emitter';
import { showErrorDialog } from './errorHandling';
import Util from './util';
import GDK from './gdk';
import { Howl } from 'howler';
import Wallet from './wallet';
import GameHistory from './gameHistory';
import { MobileCheck } from './mobileCheck';
import { Storage } from './storage';
import MiniSocial from './miniSocial';
import connector from './connector';

type HTMLElementGetter = () => HTMLElement | null;

namespace MagicUI {
    let sound = true;
    let soundMutedByFocusLost = false;

    let quickAction = false;
    let bet = 100;
    let isFreeSpin = false;
    let isBonusBet = false;
    let betLevels: Array<number> = [];
    let actionActive = false;
    let balanceValue: number;
    let updateBalance: boolean;
    let actionTime = 0;

    let actionStart: number;
    let introMissing = false;
    let balanceUpdateSound: Howl;
    let introPage = 0;
    let introPageCount: number;
    let introPageIndicators: Array<HTMLDivElement> = [];
    let bfrWinnings;
    let paytablePage = 0;
    let paytablePageCount: number;
    let paytablePageIndicators: Array<HTMLDivElement> = [];

    let sessionWinnings = 0;
    let sessionLosses = 0;
    let actionInProgress = false;
    let roundWin = 0;
    let bfrDialogOpen = true;
    let options: IOptions = {
        noSlotUI: false,
    };

    let bfrDisabled = false;
    let bfrConsumed = false;

    let menuOpened = false;

    let freeSpinsAmount = 0;

    let storage;

    const actionButtonElement: HTMLElementGetter = () => document.getElementById('action');
    const sessionWinningsElement: HTMLElementGetter = () =>
        document.getElementById('session-winnings');
    const soundButtonElement: HTMLElementGetter = () => document.getElementById('soundButton');
    const gameNameElement: HTMLElementGetter = () => document.getElementById('game-name');
    const magicUIElement: HTMLElementGetter = () => document.getElementById('magic-ui-div');
    const introElement: HTMLElementGetter = () => document.getElementById('intro');
    const mainUIElement: HTMLElementGetter = () => document.getElementById('main-ui');
    const errorOverlay: HTMLElementGetter = () => document.getElementById('error-overlay');
    const bfrUpdateDialogElement: HTMLElementGetter = () =>
        document.getElementById('bfr-update-dialog');
    const freeSpinBar: HTMLElementGetter = () => document.getElementById('free-spin-bar');
    const betButtonDesktop: HTMLElementGetter = () => document.getElementById('bet-button-desktop');
    const betButtonMobile: HTMLElementGetter = () => document.getElementById('bet-button-mobile');
    const miniSocialButton: HTMLElementGetter = () => document.getElementById('mini-social-button');
    const actionMarker: HTMLElementGetter = () => document.getElementById('action-marker');
    const menuButton: HTMLElementGetter = () => document.getElementById('menu-button');
    const gameHistory: HTMLElementGetter = () => document.getElementById('game-history');
    const payTableElement: HTMLElementGetter = () => document.getElementById('pay-table');

    //Key processing
    function handleKeyPressing(event: KeyboardEvent) {
        if (event.repeat || event.isComposing || event.keyCode === 229) {
            return;
        }
        switch (event.key) {
            case 'Escape': {
                hideWindows();
                break;
            }
            case ' ': {
                if (!menuOpened) action();
                break;
            }
            default:
                break;
        }
    }

    function backlay() {
        const backlay = document.createElement('div');
        backlay.classList.add('backlay');
        backlay.id = 'backlay';
        return backlay;
    }

    function registerHandlers() {
        window.addEventListener('visibilitychange', () => {
            soundMutedByFocusLost = document.hidden;

            EventManager.emit('magic-ui:sound', { state: sound && !soundMutedByFocusLost });
            console.debug(`VISIBILITY CHANGE, ${soundMutedByFocusLost ? 'MUTED' : 'UNMUTED'}`);
        });

        EventManager.on('gdk:show', (event) => {
            let bet = event.bet;

            if (betLevels && betLevels.indexOf(bet) < 0) {
                // clamping for out-of-array bet
                bet = betLevels[betLevels.length - 1];
            }

            renderBet(bet);
        });

        EventManager.on('gdk:config', async (event) => {
            betLevels = event.betLevels;
            renderBetLevels(betLevels);
        });

        EventManager.on('magic-ui:show-game', async () => {
            const bonusBalances = Wallet.getBonusFreeSpinsAvailable();
            setTimeout(() => {
                if (!bfrDisabled && bonusBalances.length > 0) {
                    if (!isBonusBet) {
                        MagicUI.showbfrDialog();
                    } else {
                        MagicUI.showBFRUpdateDialog();
                    }
                }
            }, 64);
        });

        EventManager.on('gdk:balance', (event) => {
            balanceValue = event.amount;
            if (!actionInProgress) {
                updateBalance = true;
                Util.setCurrency(event.currency);
                showBalance();
            }

            renderBet(bet, isBonusBet);
        });

        EventManager.on('game:winnings', (event) => {
            roundWin = event.amount;
            const winningValue = event.amount > 0 ? Util.toMoney(event.amount, true) : '-';
            const winMobile = document.getElementById('win-mobile');
            const winDesktop = document.getElementById('win-desktop');

            if (winMobile) {
                winMobile.innerHTML = winningValue;
            }

            if (winDesktop) {
                winDesktop.innerHTML = winningValue;
            }

            console.log('Winning amount: ' + winningValue);
        });

        //@ts-ignore
        EventManager.on('common:bfr-progress', (event) => {
            Wallet.updateBonusFreeSpinsConsumed(
                Wallet.parseMagicWalletBalance(event.templateId, event.quantity).batchId,
                event.freeRoundConsumed,
            );
            
            if (!event.freeRoundConsumed) {
                useBonusBalance();
            }

            bfrConsumed = event.freeRoundConsumed;

            bfrWinnings = event.currentWinnings;
        });

        EventManager.on('magic-engine:winnings', (event) => {
            recordWin(event.monetaryWinningsSum);
        });

        EventManager.on('magic-ui:show-game', () => {
            hideIntro();
            if (!options.noSlotUI) {
                showUI();
                actionButtonElement()?.classList.remove('hidden');
            } else {
                const uiBar = document.getElementById('ui-bar');

                uiBar && (uiBar.style.display = 'none');

                const action = actionButtonElement();

                if (action) {
                    action.style.display = 'none';
                    action.classList.add('hidden');
                    action.classList.add('not-used');
                }
            }
        });

        EventManager.on('engine:activation', () => {            
            setTimeout(() => {
                enableUI();
            }, 2000);

            updateBalance = true;
            showBalance();
        });

        EventManager.on('magic-ui:action', (evt) => {
            if (isBonusBet) {
                document.getElementById('play-icon')?.classList.remove('action-img');

                const playIcon = document.getElementById('play-icon');

                if (playIcon) {
                    if (evt.isFreeSpin) {
                        playIcon.innerText = (
                            Wallet.getBonusFreeSpinsAvailable()[0].quantity + 1
                        ).toString();
                    } else {
                        playIcon.innerText =
                            Wallet.getBonusFreeSpinsAvailable()[0].quantity.toString();
                    }
                }

                // @ts-ignore
            } else if (evt.bet && !evt.isFreeSpin) {
                recordLoss(evt.bet);
            }
            Util.postMessageToParent({ type: 'gameRoundStarted' });
        });

        EventManager.on('disable-ui', () => {
            deactivateActionButton();
            deactivateMenuButton();
            deactivateBetAmountButton();
            deactivateMiniSocialButton();
        });

        EventManager.on('enable-ui', () => {
            enableUI();
        });

        EventManager.on('game:done', async () => {
            enableUI();
            
            updateBalance = true;
            if (isBonusBet) {
                const playIcon = document.getElementById('play-icon');

                if (playIcon) {
                    playIcon.classList.add('action-img');
                    playIcon.innerText = '';
                }

                if (bfrConsumed) {
                    showbfrEndDialog();
                    isBonusBet = false;
                }
            }
            actionInProgress = false;
            showBalance();
            Util.postMessageToParent({ type: 'gameRoundEnded' });

            const sessionWinningsEl = sessionWinningsElement();
            if (sessionWinningsEl) {
                sessionWinningsEl.innerText =
                    Util.getCurrencySymbol() + Util.toMoney(sessionWinnings - sessionLosses);
            }

            const actionButton = actionButtonElement();

            actionButton && (actionButton.style.pointerEvents = '');
        });
    }

    export const recordLoss = (loss: number) => {
        if (isFreeSpin) {
            return;
        }
        
        sessionLosses += loss;
    };

    export const recordWin = (win: number) => {
        sessionWinnings += win;
    };

    const setUpClock = () => {
        const clock = document.getElementById('regulatory-clock');
        const sessionTimer = document.getElementById('regulatory-session-timer');
        const sessionStart = new Date();

        const setTime = () => {
            const date = new Date();
            const h = date.getHours();
            const m = date.getMinutes();
            clock && (clock.innerText = `${h}:${m.toString().padStart(2, '0')}`);

            const sessionLength = date.getTime() - sessionStart.getTime();
            const hours = Math.floor(sessionLength / 3600000)
                .toString()
                .padStart(2, '0');
            const minutes = (Math.floor(sessionLength / 60000) % 60).toString().padStart(2, '0');
            const seconds = (Math.floor(sessionLength / 1000) % 60).toString().padStart(2, '0');

            if (sessionTimer) {
                sessionTimer.innerText = `${hours.padStart(2, '0')}:${minutes.padStart(
                    2,
                    '0',
                )}:${seconds.padStart(2, '0')}`;
            }
        };

        setTime();
        setInterval(setTime, 1000);
    };

    interface IOptions {
        noSlotUI?: boolean;
        taxonomy?: number[];
        portraitModeOnly?: boolean;
        alternativeUIAddress?: string;
        gameID?: string;
        gameName?: string;
        gameVersion?: string;
        buildType?: string;
        noIntro?: 'true' | 'false';
    }

    export const init = (
        gameDiv: HTMLElement,
        callback: () => void,
        alternativeUIAddress: string,
        uiOptions?: IOptions,
    ) => {
        let magicUIBuildData = ` MagicUI ${process.env.npm_package_version} `;
        let gameBuildData = '';

        if (uiOptions) {
            const { gameID, gameVersion, buildType } = uiOptions;

            gameID && (gameBuildData += `${gameID}`);
            gameVersion && (gameBuildData += `-${gameVersion}`);
            buildType && (gameBuildData += `-${buildType}`);
        }

        storage = new Storage(uiOptions?.gameID ?? '');

        if (uiOptions) {
            options = uiOptions;
        }

        document.onkeydown = handleKeyPressing;
        //Register handlers
        registerHandlers();

        gameDiv.appendChild(backlay());
        let baseUrl = alternativeUIAddress;
        if (!baseUrl) {
            baseUrl = 'https://magic-ui.goosicorn.com';
        }

        balanceUpdateSound = new Howl({
            html5: true,
            src: [`${baseUrl}/assets/audio/balance.mp3`, `${baseUrl}/assets/audio/balance.ogg`],
        });

        balanceUpdateSound.volume(0.25);

        fetch(`${baseUrl}/html/magic-ui.html`)
            .then((response) => response.text())
            .then(async (uiHTML) => {
                const uiDiv = document.createElement('div');
                uiDiv.id = 'magic-ui-div';
                uiDiv.innerHTML = uiHTML;
                gameDiv.appendChild(uiDiv);
                uiDiv.style.display = 'none';

                console.info(
                    `🪄 ` + `%c${magicUIBuildData}` + `%c ${gameBuildData}` + ` 🪿 `,
                    'background: blue;color: white; font-weight: bold',
                    'color: white; background: black; font-weight: bold',
                );

                setUpClock();
                await injectPartials();
                await GDK.init();

                const sessionWinningsEl = sessionWinningsElement();
                sessionWinningsEl && (sessionWinningsEl.innerText = Util.toMoney(0, true));

                parent.postMessage({ type: 'gameReady' }, '*');

                let canShowIntro = true;

                if (storage.get('dontShowIntro') === true) {
                    canShowIntro = false;
                }

                if (options.noIntro === 'true') {
                    canShowIntro = false;
                }

                if (canShowIntro) {
                    const introPartial = await fetch('partials/intro/intro.json');
                    await introPartial
                        .json()
                        .then(async (introConfig) => {
                            introPageCount = introConfig.length;

                            const intro = document.getElementById('intro-content');

                            for (const [index, page] of introConfig.entries()) {
                                await fetch(`partials/intro/${page}`)
                                    .then((response) => response.text())
                                    .then((pageHTML) => {
                                        const div = document.createElement('div');
                                        div.innerHTML = pageHTML;
                                        div.id = `intro-page-${index}`;
                                        div.classList.add('intro-page');
                                        intro?.appendChild(div);
                                    });
                            }
                            setUpIntro();
                        })
                        .catch((e) => {
                            introMissing = true;
                            console.error('Missing intro');
                        });
                }


                if (options.noSlotUI) {
                    const history = gameHistory();
                    if (history) {
                        history.style.maxHeight = 'cal(100% - 100px)';
                        history.style.bottom = '0%';
                    }
                }

                document.getElementById('close-menu-button')?.addEventListener('click', () => {
                    hideMenu();
                });

                if (storage.get('mute')) {
                    const soundButton = soundButtonElement();
                    if (soundButton) {
                        soundButton.classList.add('deactive');
                        soundButton.classList.remove('active');
                    }

                    sound = false;
                }

                if (uiOptions?.portraitModeOnly && MobileCheck()) {
                    const rotatePhone = document.createElement('div');
                    rotatePhone.id = 'rotate-phone';
                    rotatePhone.innerHTML = '&nbsp;';
                    gameDiv.append(rotatePhone!);
                }

                callback();

                if (connector.getSocialLayerData()) {
                    MiniSocial.init();
                } else {
                    const miniSocialDiv = document.getElementById('mini-social');
                    miniSocialDiv.style.display = 'none';
                }
            })
            .catch((error) => {
                console.error('Unable to initialise MagicUi');
                console.error({ error });
            });
    };

    export function showbfrEndDialog() {
        setTimeout(() => {
            deactivateActionButton();
        }, 32);
        mainUIElement()?.classList.remove('hidden');
        errorOverlay()?.classList.remove('hidden');
        document.getElementById('bfr-end-dialog')?.classList.remove('hidden');
        const bfrWinningsElement = document.getElementById('bfr-winnings');
        if (bfrWinningsElement) {
            if (bfrWinnings > 0) {
                bfrWinningsElement.innerText =
                    'You won {free_spins_winnings} from your free spins!'.replace(
                        '{free_spins_winnings}',
                        Util.toMoney(bfrWinnings, true),
                    );
            } else {
                bfrWinningsElement.innerText = 'You\'ve used all your free spins!';
            }
        }
    }

    export function disablebfrDialog() {
        bfrDisabled = true;
    }

    export function showBFRUpdateDialog() {
        mainUIElement()?.classList.remove('hidden');
        errorOverlay()?.classList.remove('hidden');
        bfrUpdateDialogElement()?.classList.remove('hidden');
        const bonusBalances = Wallet.getBonusFreeSpinsAvailable();
        const bfrSpins = document.getElementById('bfr-update-spins');
        if (bfrSpins) {
            bfrSpins.innerText = bfrSpins.innerText.replace(
                '{free_spins}',
                bonusBalances[0].quantity.toString(),
            );
        }
        const bfrDenomination = document.getElementById('bfr-update-denomination');
        if (bfrDenomination) {
            const denomination = bonusBalances[0].denomination;
            bfrDenomination.innerText = bfrDenomination.innerText.replace(
                '{denomination}',
                Util.toMoney(bonusBalances[0].denomination, true, denomination < 100),
            );
        }
    }

    export function showbfrDialog() {
        deactivateActionButton();
        mainUIElement()?.classList.remove('hidden');
        errorOverlay()?.classList.remove('hidden');
        document.getElementById('bfr-dialog')?.classList.remove('hidden');
        const bonusBalances = Wallet.getBonusFreeSpinsAvailable();
        const bfrSpins = document.getElementById('bfr-spins');
        if (bfrSpins) {
            bfrSpins.innerText = bfrSpins.innerText.replace(
                '{free_spins}',
                bonusBalances[0].quantity.toString(),
            );
        }
        const bfrDenomination = document.getElementById('bfr-denomination');
        if (bfrDenomination) {
            const denomination = bonusBalances[0].denomination;
            bfrDenomination.innerText = bfrDenomination.innerText.replace(
                '{denomination}',
                Util.toMoney(bonusBalances[0].denomination, true, denomination < 100),
            );
        }

        bfrDialogOpen = true;
    }

    export function closebfrDialog() {
        if (options.noSlotUI) {
            mainUIElement()?.classList.add('hidden');
        }
        // @ts-ignore
        EventManager.emit('bfrDialog:dismissed');
        enableUI();
        errorOverlay()?.classList.add('hidden');
        document.getElementById('bfr-dialog')?.classList.add('hidden');
        bfrDialogOpen = false;
    }

    export function closeBFRUpdateDialog() {
        if (options.noSlotUI) {
            mainUIElement()?.classList.add('hidden');
        }
        bfrUpdateDialogElement()?.classList.add('hidden');
        // @ts-ignore
        EventManager.emit('bfrDialog:dismissed');
        enableUI();
        errorOverlay()?.classList.add('hidden');
        document.getElementById('bfr-dialog')?.classList.add('hidden');
        bfrDialogOpen = false;
    }

    export function closebfrEndDialog() {
        if (options.noSlotUI) {
            mainUIElement()?.classList.add('hidden');
        }
        enableUI();
        errorOverlay()?.classList.add('hidden');
        document.getElementById('bfr-end-dialog')?.classList.add('hidden');
        bfrDialogOpen = false;
    }

    function toggleButton(button: HTMLElement, isActive: boolean) {
        if (isActive) {
            button.classList.add('active');
            button.classList.remove('deactive');
        } else {
            button.classList.remove('active');
            button.classList.add('deactive');
        }
    }

    export const toggleSound = (on?: boolean) => {
        if (on !== undefined) {
            sound = on;
        } else {
            sound = !sound;
        }

        const soundButton = soundButtonElement();

        soundButton && toggleButton(soundButton, sound);
        EventManager.emit('magic-ui:sound', { state: sound });

        storage.set('mute', !sound);
    };

    export const setGameName = (gameName: string) => {
        const regulatoryGameName = document.getElementById('regulatory-game-name');
        if (regulatoryGameName) {
            regulatoryGameName.innerText = gameName || '';
        }
    };

    export const show = (gameName: string) => {
        const gameNameEl = gameNameElement();
        gameNameEl && (gameNameEl.innerText = gameName || '');

        setGameName(gameName);
        const magicUI = magicUIElement();

        magicUI && (magicUI.style.display = '');
    };

    export const showIntro = () => {
        const dontShowIntro = storage.get('dontShowIntro');
        const noIntro = options.noIntro === 'true';

        if (dontShowIntro || noIntro || introMissing) {
            EventManager.emit('magic-ui:show-game');
            return;
        }

        introElement()?.classList.remove('hidden');

        const magicUI = magicUIElement();

        magicUI && (magicUI.style.display = '');

        const page0 = document.getElementById(`intro-page-0`);

        page0 && (page0.style.transform = 'translateX(0%)');

        for (let i = 1; i < introPageCount; i++) {
            const page = document.getElementById(`intro-page-${i}`);

            page && (page.style.transform = 'translateX(300%)');
        }

        const intro = introElement();

        intro && (intro.style.visibility = 'initial');
    };

    export const hideIntro = () => {
        introElement()?.classList.add('hidden');
    };

    export const hideUI = () => {
        mainUIElement()?.classList.add('hidden');
    };

    export const showUI = () => {
        mainUIElement()?.classList.remove('hidden');
    };

    export const setVersion = (version: string) => {
        const gameName = gameNameElement();
        gameName && (gameName.title = `${process.env.npm_package_version} - ${version}`);
    };

    export const bonusBetActive = () => {
        return isBonusBet;
    };

    export const bonusBetValue = () => {
        if (Wallet.getBonusFreeSpinsAvailable().length > 0) {
            return Wallet.getBonusFreeSpinsAvailable()[0].denomination;
        } else {
            return 0;
        }
    };

    export const showFreeSpinBar = () => {
        freeSpinBar()?.classList.remove('deactive');
    };
    
    export const closeFreeSpinBar = () => {
        freeSpinBar()?.classList.add('deactive');
    };

    export const updateFreeSpins = (playedSpins: number, totalSpins: number) => {
        const freeSpinsDataPlayed = document.getElementById('free-spins-data-played');
        
        if (freeSpinsDataPlayed) {
            freeSpinsDataPlayed.innerText = Math.min(totalSpins, playedSpins).toString();
        }

        const freeSpinsDataTotal = document.getElementById('free-spins-data-total');

        freeSpinsDataTotal && (freeSpinsDataTotal.innerText = totalSpins.toString());
    };

    export const updateFreeSpinWinnings = (freeSpinWinnings: number) => {
        const freeSpinWinningsValue = document.getElementById('free-spin-winnings-value');
        freeSpinWinningsValue && (freeSpinWinningsValue.innerText = Util.toMoney(freeSpinWinnings));
    };

    export const toggleQuickAction = () => {
        quickAction = !quickAction;
        const quickActionButtonElement = document.getElementById('quickActionButton');

        quickActionButtonElement && toggleButton(quickActionButtonElement, quickAction);
    };

    export const action = (isGameFreeSpin: boolean = false) => {
        if (!actionInProgress && actionActive && !betSelectorOn()) {
            if (!isBonusBet && bet > balanceValue) {
                if (betLevels.some((betLevel) => betLevel <= bet)) {
                    console.error('Enough for smaller bet');
                    showErrorDialog({ errorCode: '6ONCH4GK1N' });
                } else {
                    console.error('Not enough for smaller bet');
                    showErrorDialog({ errorCode: '6ONCH4GK1N' });
                }
            } else {                
                actionInProgress = true;

                if (isBonusBet) {
                    // @ts-ignore
                    EventManager.emit('magic-ui:action', {
                        bet: bet,
                        isFreeSpin: isGameFreeSpin,
                        bfr: Wallet.getBonusFreeSpinsAtDenomination(bet).bonusString,
                    });
                } else {
                    // @ts-ignore
                    EventManager.emit('magic-ui:action', { bet: bet, isFreeSpin });
                }

                actionButtonElement()?.classList.add('action-deactive');
                actionButtonElement()?.classList.remove('active');

                const actionButton = actionButtonElement();

                actionButton && (actionButton.style.pointerEvents = 'none');
                actionMarker()?.classList.add('glow');
                actionActive = false;
                updateBalance = false;
                actionStart = Date.now();
            }
        }
    };

    const renderBet = (newBet: number, bonus = false) => {
        const quantity = Wallet.getBonusFreeSpinsAtDenomination(newBet)?.quantity;
        document.getElementById('bonus-bet' + bet + ':' + quantity)?.classList.remove('active');

        document.getElementById('bet' + bet)?.classList.remove('active');

        bet = newBet;

        const parsedBet = Util.toMoney(bet, true);

        const betMobile = document.getElementById('bet-mobile');
        const betDesktop = document.getElementById('bet-desktop');

        if (betMobile) {
            betMobile.innerHTML = bonus ? `${parsedBet}` : `${parsedBet}`;
        }

        if (betDesktop) {
            betDesktop.innerHTML = bonus ? `${parsedBet}` : `${parsedBet}`;
        }

        if (bonus) {
            const bonusBalance = Wallet.getBonusFreeSpinsAtDenomination(newBet);
            if (bonusBalance && bonusBalance.quantity > 0) {
                document
                    .getElementById('bonus-bet' + bet + ':' + quantity)
                    ?.classList.add('active');
            }
            betButtonMobile()?.classList.add('bonus');
            betButtonDesktop()?.classList.add('bonus');
        } else {
            document.getElementById('bet' + bet)?.classList.add('active');
            betButtonMobile()?.classList.remove('bonus');
            betButtonDesktop()?.classList.remove('bonus');
        }

        console.log('Bet value: ' + parsedBet);
    };

    export const useBonusBalance = () => {
        const bonusBalances = Wallet.getBonusFreeSpinsAvailable();
        selectBet(bonusBalances[0].denomination, true);
        if (bfrDialogOpen) {
            closebfrDialog();
        }

        document.getElementById('bet-lock-icon')?.classList.remove('hidden');
        document.getElementById('bet-lock-icon-mobile')?.classList.remove('hidden');

        const desktopButton = betButtonDesktop();
        const mobileButton = betButtonMobile();

        desktopButton && (desktopButton.style.pointerEvents = 'none');
        mobileButton && (mobileButton.style.pointerEvents = 'none');
    };

    export const useBalance = () => {
        Wallet.getBonusFreeSpinsAvailable();
        selectBet(bet, false);
        closebfrEndDialog();
        document.getElementById('bet-lock-icon')?.classList.add('hidden');
        document.getElementById('bet-lock-icon-mobile')?.classList.add('hidden');

        const desktopButton = betButtonDesktop();
        const mobileButton = betButtonMobile();

        desktopButton && (desktopButton.style.pointerEvents = 'unset');
        mobileButton && (mobileButton.style.pointerEvents = 'unset');
    };

    export const startFreeSpins = () => {
        isFreeSpin = true;
    };

    export const endFreeSpins = () => {
        isFreeSpin = false;
    };

    const selectBet = (newBet: number, bonus = false) => {
        isBonusBet = bonus;
        if (newBet !== bet) {
            //@ts-ignore
            EventManager.emit('magic-ui:bet-changed', newBet);
        }
        renderBet(newBet, bonus);
        hideBetSelector();
    };

    export const setBalance = (balance: number) => {
        const readableBalance = Util.toMoney(balance, true);
        console.log('Updating balance: ' + balance);

        const mobile = document.getElementById('balance-mobile');
        const desktop = document.getElementById('balance-desktop');

        mobile && (mobile.innerHTML = readableBalance);
        desktop && (desktop.innerHTML = readableBalance);
    };

    const showBalance = () => {
        if (updateBalance) {
            if (roundWin > 0 && !isMuted()) {
                // @ts-ignore
                balanceUpdateSound.play();
            }

            setBalance(balanceValue);
            updateBalance = false;
        }
    };

    const enableUI = () => {
        if (isFreeSpin) {
            actionActive = true;

            return;
        }

        const now = Date.now();
        let remainingWait = 0;
        if (actionStart) {
            remainingWait = Math.max(0, actionTime - (now - actionStart));
        }

        setTimeout(() => {
            actionActive = true;
            actionButtonElement()?.classList.add('active');
            actionButtonElement()?.classList.remove('action-deactive');
            actionMarker()?.classList.remove('glow');
            document.onkeydown = handleKeyPressing;

            activateMenuButton();
            activateBetAmountButton();
            activateMiniSocialButton();
        }, remainingWait);
    };

    const deactivateActionButton = () => {
        actionButtonElement()?.classList.remove('active');
        actionButtonElement()?.classList.add('action-deactive');

        document.onkeydown = null;
    };

    const deactivateMenuButton = () => {
        menuButton()?.classList.remove('active');
        menuButton()?.classList.add('inactive');
    };

    const activateMenuButton = () => {
        menuButton()?.classList.remove('inactive');
    };

    const deactivateBetAmountButton = () => {
        betButtonMobile()?.classList.add('inactive');
    };

    const deactivateMiniSocialButton = () => {
        miniSocialButton()?.classList.add('inactive');
    };

    const activateMiniSocialButton = () => {
        miniSocialButton()?.classList.remove('inactive');
    };

    const activateBetAmountButton = () => {
        betButtonMobile()?.classList.remove('inactive');
    };

    function renderBetLevels(betLevels: Array<number>) {
        const betElements = document.getElementById('bet-elements');
        betLevels.forEach((betLevel) => {
            const betLevelDiv = document.createElement('div');
            betLevelDiv.id = 'bet' + betLevel;
            betLevelDiv.innerHTML = Util.toMoney(betLevel, true);
            betLevelDiv.classList.add('button', 'bet-value-button');
            betLevelDiv.onclick = () => selectBet(betLevel);

            betElements?.appendChild(betLevelDiv);
        });
    }

    async function injectPartials() {
        const payTablePartial = await fetch(`partials/paytable.json`);
        const paytableConfig = await payTablePartial.json();

        paytablePageCount = paytableConfig.length;
        const payTable = document.getElementById('pay-table-content');
        for (const [index, page] of paytableConfig.entries()) {
            await fetch(`partials/${page}`)
                .then((response) => response.text())
                .then((pageHTML) => {
                    const div = document.createElement('div');
                    div.innerHTML = pageHTML;
                    div.id = `pay-table-page-${index}`;
                    div.classList.add('pay-table-page');
                    payTable?.appendChild(div);
                    div.style.display = 'none';
                });
        }
        setUpPaytable();

        const gameRulesPartial = fetch(`partials/game-rules.html`)
            .then((response) => response.text())
            .then((uiHTML) => {
                const gameRules = document.getElementById('game-rules-content');
                console.debug('Game rules loaded');
                gameRules && (gameRules.innerHTML = uiHTML);
            })
            .catch((error) => {
                console.error('No game rules partial found in game');
            });

        const fetchPaylines = await fetch('partials/paylines.json');
        const paylinesEl = document.getElementById('paytable-paylines');
        if (paylinesEl && fetchPaylines.status === 200) {
            const paylines = (await fetchPaylines.json()) as number[][];

            await Promise.all([payTablePartial, gameRulesPartial]);

            paylines.forEach((payline) => {
                const paylineDiv = createPayline(payline);
                const payTable = document.getElementById('pay-table-content');

                paylinesEl.appendChild(paylineDiv);
            });
        }
    }

    const createPayline = (spots: number[]) => {
        let rows = 3;
        let reels = 5;

        if (options.taxonomy) {
            rows = Math.max(...options.taxonomy);
            reels = options.taxonomy.length;
        }
        const div = document.createElement('div');
        div.classList.add('payline');
        div.style.margin = '5px;';
        div.style.height = `${rows * 20}px`;

        for (let reel = 0; reel < reels; reel++) {
            for (let row = 0; row < rows; row++) {
                const spotEl = document.createElement('div');
                spotEl.classList.add('payline-spot');
                spotEl.id = `spot-${reel}-${row}`;
                div.appendChild(spotEl);
            }
        }

        spots.forEach((row, reel) => {
            const spotEl = div.querySelector(`#spot-${reel}-${row}`);
            if (spotEl) {
                spotEl.classList.add('lit');
            }
        });
        return div;
    };

    function setUpIntro() {
        const indicatorContainer = document.getElementById('intro-page-indicators');
        for (let i = 0; i < introPageCount; i++) {
            const indicator = document.createElement('div');
            indicator.classList.add('intro-page-indicator');
            if (i === 0) {
                indicator.classList.add('highlit');
            }
            introPageIndicators.push(indicator);
            indicatorContainer?.appendChild(indicator);
        }

        if (introPageCount === 1) {
            const introArrows = document.getElementById('intro-arrows');
            const introPageIndicatorsElement = document.getElementById('intro-page-indicators');

            if (introArrows) {
                introArrows.style.visibility = 'hidden';
            }

            if (introPageIndicatorsElement) {
                introPageIndicatorsElement.style.visibility = 'hidden';
            }
        }
    }

    function setUpPaytable() {
        const indicatorContainer = document.getElementById('pay-table-page-indicators');

        for (let i = 0; i < paytablePageCount; i++) {
            const indicator = document.createElement('div');
            indicator.classList.add('pay-table-page-indicator');
            if (i === 0) {
                indicator.classList.add('highlit');
            }
            paytablePageIndicators.push(indicator);

            indicatorContainer?.appendChild(indicator);
        }
    }

    function betSelectorOn() {
        const betSelector = document.getElementById('bet-selector');

        return betSelector !== null && betSelector.style.display !== 'none';
    }

    function menuOn() {
        return menuOpened;
    }

    function safeClassOp(id: string, op: string, className: string) {
        if (document.getElementById(id)) {
            if (op === 'add') {
                const element = document.getElementById(id);
                if (element) {
                    element.classList.add(className);
                }
            }
            if (op === 'remove') {
                const element = document.getElementById(id);
                if (element) {
                    element.classList.remove(className);
                }
            }
        }
    }

    function showWindow(
        checker: () => boolean,
        mainElementId: string,
        prefix: string,
        toggler: () => void,
    ) {
        if (!checker()) {
            const element = document.getElementById(mainElementId);
            if (element) {
                element.style.display = 'block';
            }
            safeClassOp(prefix + '-button', 'add', 'active');
            safeClassOp(prefix + '-button-mobile', 'add', 'active');
            safeClassOp(prefix + '-button-desktop', 'add', 'active');

            const actionButton = actionButtonElement();

            actionButton && (actionButton.style.display = 'none');

            Array.from(document.getElementsByClassName('backlay')).forEach((e) => {
                e.addEventListener('click', toggler);
            });
        }
    }

    function hideWindow(
        checker: () => boolean,
        mainElementId: string,
        prefix: string,
        toggler: () => void,
    ) {
        if (checker()) {
            const element = document.getElementById(mainElementId);
            if (element) {
                element.style.display = 'none';
            }
            safeClassOp(prefix + '-button', 'remove', 'active');
            safeClassOp(prefix + '-button-mobile', 'remove', 'active');
            safeClassOp(prefix + '-button-desktop', 'remove', 'active');

            const actionButton = actionButtonElement();

            actionButton && (actionButton.style.display = 'flex');

            Array.from(document.getElementsByClassName('backlay')).forEach((e) => {
                e.removeEventListener('click', toggler);
            });
        }
    }

    const hideBetSelector = () =>
        hideWindow(betSelectorOn, 'bet-selector', 'bet', toggleBetSelector);
    const showBetSelector = () =>
        showWindow(betSelectorOn, 'bet-selector', 'bet', toggleBetSelector);

    const openSubMenu = () => {
        const menu = document.getElementById('menu');

        if (menu) {
            menu.style.display = 'none';
        }
    };

    const hideMenu = () => {
        activateMenuButton();
        hideWindow(menuOn, 'menu', 'menu', toggleMenu);
        menuOpened = false;
    };

    const showMenu = () => {
        deactivateMenuButton();
        showWindow(menuOn, 'menu', 'menu', toggleMenu);
        menuOpened = true;
    };

    export const toggleBetSelector = () => {
        if (betButtonMobile()?.classList.contains('inactive')) {
            return;
        }

        hideMenu();
        closeGameHistory();
        closeGameRules();
        closePaytable();

        if (betSelectorOn()) {
            hideBetSelector();
        } else {
            showBetSelector();
        }
    };

    export const toggleMenu = () => {
        hideBetSelector();

        if (menuOpened) {
            return;
        }

        if (!menuButton()?.classList.contains('inactive')) {
            showMenu();
        }
    };

    export const disableMenuButton = () => {
        menuButton()?.classList.add('disabled');
    };

    export const enableMenuButton = () => {
        menuButton()?.classList.remove('disabled');
    };

    export const openGameHistory = () => {
        openSubMenu();

        gameHistoryBack();
        disableMenuButton();

        GameHistory.fetchGameHistory();

        const actionButton = actionButtonElement();
        const history = gameHistory();

        actionButton && (actionButton.style.display = 'none');
        history && (history.style.visibility = 'unset');
    };

    export const closeGameHistory = () => {
        hideMenu();
        const actionButton = actionButtonElement();

        if (!options.noSlotUI) {
            actionButton && (actionButton.style.display = 'flex');
            enableMenuButton();
        } else {
            actionButton && (actionButton.style.display = 'none');
        }

        const history = gameHistory();

        history && (history.style.visibility = 'hidden');
    };

    export const gameHistoryBack = () => {
        const roundsTable = document.getElementById('game-history-result-table');
        const detailsTable = document.getElementById('game-history-round-details');
        const historyContainer = document.getElementById('game-history-table-container');

        roundsTable && (roundsTable.style.display = 'table');
        detailsTable && (detailsTable.style.display = 'none');
        if (historyContainer) {
            historyContainer.style.overflow = 'hidden'; // disable scrolling
            historyContainer.scrollTop = 0;
            historyContainer.style.overflow = 'auto';
        }
    };

    export function paytableNextPage() {
        const currentPayTablePageElement = document.getElementById(
            `pay-table-page-${paytablePage}`,
        );

        if (currentPayTablePageElement) {
            currentPayTablePageElement.style.display = 'none';
        }

        paytablePageIndicators[paytablePage].classList.remove('highlit');
        paytablePage++;
        if (paytablePage >= paytablePageCount) {
            paytablePage = 0;
        }
        paytablePageIndicators[paytablePage].classList.add('highlit');

        const nextPayTablePageElement = document.getElementById(`pay-table-page-${paytablePage}`);

        if (nextPayTablePageElement) {
            nextPayTablePageElement.style.display = 'block';
        }
    }

    export function paytablePreviousPage() {
        const currentPayTablePageElement = document.getElementById(
            `pay-table-page-${paytablePage}`,
        );

        currentPayTablePageElement && (currentPayTablePageElement.style.display = 'none');

        paytablePageIndicators[paytablePage].classList.remove('highlit');
        paytablePage--;
        if (paytablePage < 0) {
            paytablePage = paytablePageCount - 1;
        }
        paytablePageIndicators[paytablePage].classList.add('highlit');

        const nextPayTablePageElement = document.getElementById(`pay-table-page-${paytablePage}`);

        nextPayTablePageElement && (nextPayTablePageElement.style.display = 'block');
    }

    export function introNextPage() {
        let page = document.getElementById(`intro-page-${introPage}`);
        page?.classList.remove('from-right');
        page?.classList.remove('from-left');
        page?.classList.remove('to-right');
        page?.classList.add('to-left');

        introPageIndicators[introPage].classList.remove('highlit');
        introPage++;
        if (introPage >= introPageCount) {
            introPage = 0;
        }
        page = document.getElementById(`intro-page-${introPage}`);
        introPageIndicators[introPage].classList.add('highlit');
        page?.classList.remove('to-left');
        page?.classList.remove('from-left');
        page?.classList.remove('to-right');
        page?.classList.add('from-right');
    }

    export function introPreviousPage() {
        let page = document.getElementById(`intro-page-${introPage}`);
        page?.classList.remove('from-right');
        page?.classList.remove('from-left');
        page?.classList.remove('to-left');
        page?.classList.add('to-right');

        introPageIndicators[introPage].classList.remove('highlit');
        introPage--;
        if (introPage < 0) {
            introPage = introPageCount - 1;
        }
        page = document.getElementById(`intro-page-${introPage}`);
        introPageIndicators[introPage].classList.add('highlit');
        page?.classList.remove('to-left');
        page?.classList.remove('from-right');
        page?.classList.remove('to-right');
        page?.classList.add('from-left');
    }

    export const openPaytable = () => {
        openSubMenu();
        for (let i = 0; i < paytablePageCount; i++) {
            const payTablePageElement = document.getElementById(`pay-table-page-${i}`);
            if (payTablePageElement) {
                payTablePageElement.style.display = 'none';
            }
            paytablePageIndicators[i].classList.remove('highlit');
        }

        paytablePage = 0;
        paytablePageIndicators[paytablePage].classList.add('highlit');
        const payTablePageElement = document.getElementById(`pay-table-page-${paytablePage}`);
        if (payTablePageElement) {
            payTablePageElement.style.display = 'block';
        }

        const actionButton = actionButtonElement();
        const payTable = payTableElement();

        actionButton && (actionButton.style.display = 'none');

        if (payTable) {
            payTable.style.visibility = 'initial';
            payTable.classList.remove('hidden');
        }
        const payTablePage0 = document.getElementById('pay-table-page-0');
        if (payTablePage0) {
            payTablePage0.style.display = 'block';
        }
    };

    export const closePaytable = () => {
        hideMenu();
        const actionButton = actionButtonElement();
        const payTable = payTableElement();

        actionButton && (actionButton.style.display = 'flex');

        if (payTable) {
            payTable.style.visibility = 'hidden';
            payTable.classList.add('hidden');
        }
    };

    export const openGameRules = (noSlotFooter = false) => {
        const actionButton = actionButtonElement();

        openSubMenu();
        hideUI();
        actionButton && (actionButton.style.display = 'none');

        document.getElementById('game-rules')?.classList.remove('hidden');

        if (noSlotFooter) {
            const simultaneousPayLinesElement = document.getElementById(
                'game-rules-simultaneous-paylines',
            );
            const gameRulesHowToPlay = document.getElementById('game-rules-how-to-play');

            if (simultaneousPayLinesElement) {
                simultaneousPayLinesElement.style.display = 'none';
            }

            if (gameRulesHowToPlay) {
                gameRulesHowToPlay.style.display = 'none';
            }
        }
    };

    export const closeGameRules = () => {
        hideMenu();
        if (!options.noSlotUI) {
            showUI();
        }

        const actionButton = actionButtonElement();

        actionButton && (actionButton.style.display = 'flex');
        document.getElementById('game-rules')?.classList.add('hidden');
    };

    const hideWindows = () => {
        hideWindow(betSelectorOn, 'bet-selector', 'bet', toggleBetSelector);
        hideMenu();
    };

    export const isMuted = () => {
        return !sound;
    };

    export const toggleIntro = () => {
        let dontShowIntro = storage.get('dontShowIntro') ?? false;

        storage.set('dontShowIntro', !dontShowIntro);

        const introSkipToggleStem = document.getElementById('intro-skip-toggle-stem');
        const introSkipToggle = document.getElementById('intro-skip-toggle');

        if (dontShowIntro) {
            introSkipToggleStem?.classList.remove('enabled');
            introSkipToggle?.classList.remove('enabled');
        } else {
            introSkipToggleStem?.classList.add('enabled');
            introSkipToggle?.classList.add('enabled');
        }
    };

    export const setGameRTP = (rtp: string) => {
        const gameRTPElement = document.getElementById('game-rules-rtp');
        if (gameRTPElement) {
            gameRTPElement.innerText = `The return to player(RTP): ${rtp}`;
        }
    };

    export const openSocialIframe = () => {
        MiniSocial.openSocialIframe();
    };

    export const closeSocialIframe = () => {
        MiniSocial.closeSocialIframe();
    };
}

export default MagicUI;
