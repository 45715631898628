import IConnectedEvent from '@lindar-joy/goosicorn-event-emitter/src/ts/events/connectedEvent';

module Util {
    let currency: string;
    let country;
    let jurisdiction;

    export function init(connectedEvent: IConnectedEvent) {
        currency = connectedEvent.currency;
        country = connectedEvent.country;
        jurisdiction = connectedEvent.jurisdiction;
    }

    export function setCurrency(currencyString: string) {
        currency = currencyString;
    }

    export function getCurrency(): string {
        return currency;
    }

    export function getCurrencySymbol(): string {
        const formatter = new Intl.NumberFormat('en', {
            style: 'currency',
            currency: getCurrency(),
        });

        return formatter.formatToParts(0)[0].value;
    }

    export function toMoney(
        amount: number,
        withSymbol: boolean = false,
        toPence: boolean = false,
    ): string {
        function minorCurrencyUnitScaler(currency: string) {
            //Add here more currencies if needed
            switch (currency) {
                case 'JPY':
                    return 1;
                default:
                    return 100;
            }
        }

        const getLang = () => navigator.language || (navigator.languages || ['en'])[0];

        if (currency) {
            if (toPence) {
                return `${amount}p`;
            }
            const formatter = new Intl.NumberFormat(
                getLang(),
                withSymbol
                    ? {
                          style: 'currency',
                          currency: currency,
                      }
                    : {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      },
            );
            return formatter.format(amount / minorCurrencyUnitScaler(currency));
        } else {
            console.error('Currency is not set yet');
            return '0';
        }
    }

    export function postMessageToParent(message: any) {
        const isInIframe = window.self !== window.top;

        if (isInIframe) {
            // Send message to parent if in an iframe
            parent.postMessage(message, "*");
        } else {
            console.log("Running as standalone, skipping parent.postMessage.");
        }

        // Always handle locally
        window.postMessage(message, "*");
    }
}

export default Util;
